<template>
  <div id="CategoryTable">
    <b-table
      v-if="categories"
      striped
      hoverable
      :data="categories"
      @click="navigateToCategoryDetail"
      style="cursor: pointer;"
    >
      <template slot-scope="props">
        <b-table-column field="date" label="Date">
          {{ props.row.create_date | dateformatter }}
        </b-table-column>

        <b-table-column field="image" label="Image">
          <figure class="image is-128x128">
            <img class="cover-center" :src="props.row.cover_image" />
          </figure>
        </b-table-column>

        <b-table-column field="title" label="Title">
          {{ props.row.title.en }}
        </b-table-column>

        <b-table-column field="subtitle" label="Description">
          {{ props.row.description.en }}
        </b-table-column>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import Helpers from "@/utils/Helpers";
import CategoryApi from "@/apis/CategoryApi";

export default {
  name: "CategoryTable",
  data() {
    return {
      categories: null
    };
  },
  filters: {
    dateformatter: function(value) {
      if (!value) return "";
      return Helpers.dateFormatter(value);
    }
  },
  methods: {
    getCategoryList: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CategoryApi.requestCategoryList();
      loadingComponent.close();
      if (response && response.length > 0) {
        this.categories = response;
      }
    },
    navigateToCategoryDetail: function(selectedCategory) {
      this.$router.push({
        name: "category-detail",
        params: { category: selectedCategory }
      });
    }
  },
  mounted() {
    this.getCategoryList();
  }
};
</script>

<style scoped>
.cover-center {
  object-position: center;
  object-fit: cover;
  height: 128px;
}
.clickable-icon {
  cursor: pointer;
  margin-right: 0.5rem;
}
</style>
